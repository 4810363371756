$desk: 1024px;
$mb: 1023px;

.wrapper {
  min-height: 100vh;
  overflow: hidden;
}

body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

*{
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
  line-height: normal;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: #170C61;
  font-weight: 500;
  font-family: 'Montserrat' !important;
  font-size: 1.5rem;
  line-height: normal;
  background-color: #fff;
}

html {
  font-size: 2.6667vw;
}

.container {
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 100%;
  width: 100%;
}

.h-full {
  height: calc(
          100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
  );
  overflow: hidden;
  position: relative;
}

@supports (-webkit-touch-callout: none) {
  .h100 {
    height: -webkit-fill-available;
  }
}

.h100 {
  height: 100%;
}

.sc__loader {
  background: linear-gradient(201deg, #180C61 15.19%, #282781 92.04%);
  position: relative;

  &:before{
    content: '';
    background: url("../img/decor-1.svg") no-repeat center;
    background-size: contain;
    width: 61.3rem;
    height: 70.1rem;
    position: absolute;
    top: 0;
    left: -3.6rem;
  }

  &:after{
    content: '';
    background: url("../img/decor-2.svg") no-repeat center;
    background-size: contain;
    width: 27.1rem;
    height: 27.1rem;
    position: absolute;
    top: 18.1rem;
    right: -0.62rem;
  }
}

.loader__img {
  width: 31.9rem;
  margin: 11vh auto 7rem;

  img {
    display: block;
    width: 100%;
  }
}

.loading {
  background: url("../img/loader.png") no-repeat center;
  background-size: contain;
  width: 4.1rem;
  height: 4.1rem;
  margin: 0 auto;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.star {
  background: url("../img/star.png") no-repeat center;
  background-size: contain;
  width: 14.2rem;
  height: 0.8rem;
  position: absolute;
  right: 100%;
  margin-right: 4rem;
  animation-name: moving;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: moving;
  -webkit-animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  will-change: transform;

  &--1 {
    top: 12vh;
    animation-delay: 0.5s;
  }

  &--2 {
    top: 23vh;
  }

  &--3 {
    top: 62vh;
    animation-delay: 1s;
  }
}

.star-v {
  background: url("../img/star-2.svg") no-repeat center;
  background-size: contain;
  width: 0.8rem;
  height: 14.2rem;
  position: absolute;
  bottom: 100%;
  margin-bottom: 4rem;
  animation-name: moving2;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: moving2;
  -webkit-animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  will-change: transform;

  &--1 {
    left: 2.7rem;
    animation-delay: 0.1s;
  }

  &--2 {
    left: 10.2rem;
    animation-delay: 0.65s;
  }

  &--3 {
    left: 18rem;
    animation-delay: 1s;
  }

  &--4 {
    left: 24.3rem;
  }

  &--5 {
    left: 34.6rem;
    animation-delay: 0.4s;
  }
}

.status__txt {
  color: #FFF;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 7rem;

  p {
    margin: 0 0 2rem 0;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }
}

@keyframes moving {
  from {
    transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(70rem,0,0);
  }
}

@-webkit-keyframes moving {
  from {
    transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(70rem,0,0);
  }
}

.sc__main {
  background: #F0F4FF;

  .container {
    padding-top: 1.5rem;
    padding-bottom: 3.7rem;
  }
}

.steps {
  &__it {
    &-bl {
      position: relative;
      height: 14.4rem;
      padding-left: 4.8rem;
    }

    &--2 {
      .steps__it-icon {
        margin-top: 2.2rem;

        img {
          width: 5.5rem;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &--3 {
      .steps__it-descr {
        margin-bottom: 1.2rem;
      }
    }

    &-numb {
      position: absolute;
      color: #DADDEF;
      font-size: 3rem;
      top: 2.5rem;
      left: 1.7rem;
      line-height: normal;
      font-weight: 500;
      z-index: 2;
    }

    &-descr {
      color: #170C61;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: normal;
      position: relative;
      z-index: 2;
    }

    &-icon {
      width: 6rem;
      min-width: 6rem;
      margin-right: 2.7rem;
      margin-top: 1.2rem;
      position: relative;
      z-index: 2;

      img {
        display: block;
        width: 100%;
      }
    }

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

.sc__main-hd {
  margin-bottom: 0.3rem;
}

.logo {
  width: 15.5rem;

  img {
    display: block;
    width: 100%;
  }
}

.sc__main {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background: url("../img/decor-3.svg") no-repeat center;
    background-size: contain;
    width: 52.6rem;
    height: 37.7rem;
    top: -11rem;
    left: 50%;
    margin-left: 1rem;
  }

  &:after {
    content: '';
    position: absolute;
    background: url("../img/decor-4.svg") no-repeat center;
    background-size: contain;
    width: 52.6rem;
    height: 37.7rem;
    bottom: 5.7rem;
    left: 50%;
    margin-left: -63.9rem;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.btn {
  box-shadow: none !important;
  width: 100%;
  display: block;
  border: none !important;
  cursor: pointer;
  height: 5rem;
  border-radius: 1.8rem;
  background: linear-gradient(90deg, #180C61 9.25%, #282781 97.91%);
  color: #fff !important;
  padding: 0 3rem;
  color: #FFF;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &[disabled] {
    background: linear-gradient(90deg, #888 9.25%, #D9D9D9 97.91%);
    color: rgba(#fff, 0.7) !important;
  }
}

.sc__action {
  margin-top: auto;
}

.icon__right {
  width: 2.6rem;
  height: 1.6rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.start__box {
  position: relative;
  height: 45.1rem;
  padding-top: 4rem;

  &--draw {
    padding-top: 6.5rem;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &-title {
    color: #170C61;
    font-size: 2rem;
    font-weight: 600;
    line-height: normal;
    position: relative;
    z-index: 2;
    margin-bottom: 3.6rem;
  }
}

.obj {
  &__it {
    width: 22rem;
    height: 22rem;
    margin: 0 auto;
    position: relative;
    &-img {
      position: absolute;
      left: 50%;
      width: 40rem;
      margin-left: -20rem;
      top: 0;

      img {
        display: block;
        width: 100%;
      }
    }
  }
}

.draw__object {
  .obj__it {
    width: 24rem;
    height: 24rem;

    &-img {
      width: 44rem;
      margin-left: -22rem;
    }
  }

  .obj__it--2 {
    .obj__it-img {
      margin-left: -21rem;
    }
  }

  .obj__it--3{
    .obj__it-img{
      width: 36rem;
      margin-left: -19rem;
      top: 4rem;
    }
  }

  .obj__it--4{
    .obj__it-img {
      width: 32rem;
      margin-left: -15.5rem;
      top: 4rem;
    }
  }

  .obj__it--1 {
    .obj__it-img {
      margin-left: -20rem;
      top: 1rem;
    }
  }

  .obj__it--0 {
    .obj__it-img {
      width: 32rem;
      margin-left: -16rem;
      top: 5rem;
    }
  }
}

.obj__it--1 {
  .obj__it-img {
    margin-left: -18rem;
  }
}

.obj__it--2 {
  .obj__it-img {
    margin-left: -19rem;
  }
}

.obj__it--3{
  .obj__it-img{
    width: 36rem;
    margin-left: -19rem;
    top: 1rem;
  }
}

.obj__it--4{
  .obj__it-img {
    width: 28rem;
    margin-left: -13.5rem;
    top: 3rem;
  }
}

.obj__it--0 {
  .obj__it-img {
    width: 30rem;
    margin-left: -15rem;
    top: 5rem;
  }
}

.dots {
  position: absolute;
  z-index: 5;
  bottom: 3rem;
  display: flex;
  width: 100%;
  justify-content: center;
  left: 0;

  button {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #D9D9D9;
    border: none !important;
    cursor: pointer;
    will-change: background-color;
    transition: background-color 0.2s ease;

    &:not(:last-child){
      margin-right: 1.4rem;
    }

    &.active {
      background-color: #E84545;
    }
  }
}

.sl__nav {
  position: absolute;
  width: 4.8rem;
  height: 4.8rem;
  top: 50%;
  margin-top: -2.4rem;
  z-index: 5;
  cursor: pointer;

  &--prev {
    left: -1.2rem;
  }

  &--next {
    right: -1.2rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.obj__el {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.action-group {
  cursor: pointer;
  color: transparent;
  pointer-events: bounding-box;

  &.active {
    path {
      stroke-width: 4;
      stroke: #000;
    }
  }

  path {
    fill: currentColor;
  }
}

.colors__box {
  position: absolute;
  left: 0;
  height: 22.1rem;
  right: 0;
  bottom: 0;
  background: #fff;
  border: 1px solid #E5E8F7;
  box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.07);
  border-radius: 2.4rem 2.4rem 0 0;

  .keen-slider__slide {
    padding: 2rem;
  }

  .dots {
    top: 10.6rem;
    bottom: auto;
  }
}

.icon__checked {
  width: 1.1rem;
  height: 0.9rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.icon__done {
  width: 1.9rem;
  height: 1.5rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.icon__timer {
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  background: url("../img/timer.svg") no-repeat center;
  background-size: contain;
  margin-right: 1rem;
}

.countdown__box {
  border-radius: 1.9rem;
  background: #FFF;
  height: 3.8rem;
  box-shadow: 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.05);
  padding: 0 1.4rem;

  span {
    color: #170C61;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
    min-width: 4.6rem;
    text-align: center;
  }
}

.colors__list {
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
}

.colors__it {
  height: 2.8rem;
  border-radius: 1.2rem;
  position: relative;
}

.colors__it-border {
  left: -0.3rem;
  right: -0.3rem;
  bottom: -0.3rem;
  top: -0.3rem;
  border-width: 1px;
  border-style: solid;
  position: absolute;
  border-radius: 1.4rem;
}

.finish__img-box {
  position: relative;
  width: 28rem;
  height: 26.6rem;
  margin-bottom: 2rem;
  margin-right: auto;
  margin-left: auto;
}

.finish__box {
  padding-top: 4rem;
}

.finish__bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.finish__box-title {
  color: #FFF;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 5.3rem;
  line-height: normal;
}

.finish__swipe-icon {
  width: 6.7rem;
  height: 6.5rem;
  margin: 0 auto 0.6rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.finish__swipe-title {
  color: #FFF;
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.finish__img-bl {
  position: relative;
  z-index: 3;
  width: 19.2rem;
  height: 19.2rem;

  .obj__it-img {
    position: absolute;
    left: 50%;
    width: 36rem;
    margin-left: -18rem;
    top: 0;

    &.finish--0{
      width: 26rem;
      margin-left: -13rem;
      top: 2rem;
    }

    &.finish--1{
      width: 34rem;
      margin-left: -15.5rem;
    }

    &.finish--3{
      width: 30rem;
      margin-left: -15rem;
      top: 2rem;
    }

    &.finish--4{
      width: 26rem;
      margin-left: -12rem;
      top: 2rem;
    }
  }
}

.finish__over {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

@keyframes moving2 {
  from {
    transform: translate3d(0, 0,0);
  }
  to {
    transform: translate3d(0, 130vh,0);
  }
}

@-webkit-keyframes moving2 {
  from {
    transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(0, 130vh,0);
  }
}

.sc__main--success {
  position: relative;

  .logo {
    position: absolute;
    left: 2rem;
    top: 1.5rem;
  }

  .container {
    padding-top: 5rem;
  }
}

.success__box {
  position: relative;
  height: 47.1rem;

  &-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    img {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &-title {
    color: #FFF;
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1.6rem;
  }

  &-descr {
    color: #FFF;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.2rem;
  }
}

.success__box-ct {
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  top: 15.7rem;
}

.succes__after {
  position: absolute;
  bottom: 3.3rem;
  z-index: 2;
  left: 0;
  width: 100%;
  color: #170C61;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  a {
    color: #768DED;
    border-bottom: 1px solid #768DED;
  }
}

.hint {
  position: absolute;
  left: 1rem;
  z-index: 4;
  top: 2.7rem;

  &:hover {
    .hint__message {
      opacity: 1;
      visibility: visible;
    }
  }

  &__toggle {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1;
    background: linear-gradient(90deg, #190D62 0%, #282780 100%);
  }

  &__message {
    border: 1px solid #E5E8F7;
    background-color: #fff;
    box-shadow: 0 0.4rem 3rem rgba(0, 0, 0, 0.10);
    left: 100%;
    margin-left: 1.4rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 1.6rem;
    position: absolute;
    color: #170C61;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    border-radius: 1.2rem;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;

    &:before {
      content: '';
      width: 1.2rem;
      height: 1.2rem;
      border-left: 1px solid #E5E8F7;
      border-top: 1px solid #E5E8F7;
      transform: rotate(-45deg);
      position: absolute;
      background: #fff;
      right: 100%;
      top: 50%;
      margin-right: -0.5rem;
      margin-top: -0.6rem;
    }
  }
}

.status__txt--error {
  max-width: 27.5rem;
  margin-left: auto;
  margin-right: auto;
}

.desk__lock {
  display: none !important;
  background: url("../img/lock-bg.jpg") no-repeat center;
  background-size: cover;
  position: fixed;
  top: 0;
  z-index: 200;
  left: 0;
  right: 0;
  bottom: 0;
}

.desk__lock-ct {
  width: 128.6rem;
}

.desk__lock-img{
  width: 102rem;

  img {
    display: block;
    width: 100%;
  }
}

.desk__lock-info {
  padding-top: 15.7rem;
  width: 48.8rem;
  margin-left: auto;
  margin-right: 0;
  position: relative;
}

.desk__lock-info-descr {
  color: #DADADA;
  font-size: 4rem;
  font-weight: 600;
  line-height: normal;
}

.desk__lock-info-icon{
  position: absolute;
  right: 8.3rem;
  width: 13.5rem;
  top: 0;

  img {
    display: block;
    width: 100%;
  }
}


@media screen and (min-width: 580px) and (max-width: 1023px){
  html {
    font-size: 2vw;
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape){
  .container {
    max-width: 40rem;
  }

  html {
    font-size: 1.6vw;
  }

  .h-full {
    height: 64rem !important;
  }

  .wrapper {
    overflow-y: auto;
    height: calc(
            100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
    );

    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  }
}

@media screen and (min-width: $desk){
  .desk__lock {
    display: flex !important;
  }

  html {
    font-size: 0.520833vw;
  }
}